import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchOrganization = ({id, isAuthenticated}) =>{
  if (isAuthenticated)
   return axios.get(`${apiPaths.org}${id}`,{headers: {Authorization : `Bearer ${localStorage.getItem('accessToken')}`}}, status200).then((resp) => resp.data);
}

const useOrganization = (data) =>
  useQuery(['organization', data.id], () => fetchOrganization(data), {
    enabled: data.enabled,
    refetchOnWindowFocus: false,
  });

export default useOrganization;
