import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useFilterQueryBuilder = (parameters) => {
  const sources = useSelector((state)=> state.carto.dataSources);
  const widgets = useSelector((state)=> state.app.widgets);
  const {operator} = parameters;

  const queryBuilder = useCallback( (layer) => {
    if (sources[layer]?.filters) {

      const currentFilters = sources[layer]?.filters;
      let query = '';
      
      if (Object.keys(currentFilters).length > 0) {
        Object.keys(currentFilters).forEach(keyProp => {
          if (currentFilters[keyProp].in && currentFilters[keyProp].in.values.length > 0) {
            query += query == '' ? '' : ` ${operator} `;
            const col = decodeURIComponent(widgets.find((w) => w.id === currentFilters[keyProp]['in'].owner).params.field);
            query += `${col} in (`;
            currentFilters[keyProp].in.values.forEach((x, index) => {
              const clave = typeof x == 'string' ? `'${x}'` : x;
              query += index == currentFilters[keyProp].in.values.length - 1 ? `${clave}) ` : `${clave}, `;
            })
          }
          if (currentFilters[keyProp].between && currentFilters[keyProp].between.values.length > 0) {
            const size = currentFilters[keyProp].between.values.length - 1;
            const values = currentFilters[keyProp].between.values;
            query += query == '' ? '' : ` ${operator} `;
            const col = decodeURIComponent(widgets.find((w) => w.id === currentFilters[keyProp]['between'].owner).params.field);

            if (values[0][0] == null) {
              query += `${col} <= ${values[size][1] ? values[size][1] : values[size][0]} `
            } else {
              query += `${col} between ${values[0][0] != null ? values[0][0] : values[0][1]} `;
              query += `and ${values[size][1] ? values[size][1] : values[size][0]} `
            }
          }
        })
      }
      return query;
    }
    return '';
  }, [ sources, widgets, operator ]);
  
  return queryBuilder;
}

export default useFilterQueryBuilder;