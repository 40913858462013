import { featureSelectionIcons } from 'lib/react-geo-tool/packages/react-ui/src/';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  setFeatureSelectionMode,
  setFeatureSelectionEnabled
} from 'lib/react-geo-tool/packages/react-redux/src/';
import FeatureSelectionWidgetUI from './FeatureSelectionWidgetUI';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import DrawIcon from '@mui/icons-material/Draw';
import {setSelectWidgetId} from "../../../../store/appSlice";
const { RectangleIcon, CircleIcon, LassoIcon} =
  featureSelectionIcons;

const FEATURE_SELECTION_MODES = Object.freeze({
  ISOCHRONE: 'IsochroneFromMapMode',
  LASSO_TOOL: 'DrawPolygonMode',
  RECTANGLE: 'DrawRectangleMode',
  CIRCLE: 'DrawCircleFromCenterMode'
});

const FEATURE_SELECTION_MODES_MAP = {
  [FEATURE_SELECTION_MODES.ISOCHRONE]: {
    label: 'Isochrone',
    icon: <AddLocationAltIcon />
  },
  [FEATURE_SELECTION_MODES.LASSO_TOOL]: {
    label: 'Lasso tool',
    icon: <DrawIcon />
  },
  [FEATURE_SELECTION_MODES.RECTANGLE]: {
    label: 'Rectangle',
    icon: <RectangleIcon />
  },
  [FEATURE_SELECTION_MODES.CIRCLE]: {
    label: 'Circle',
    icon: <CircleIcon />
  }
};

function FeatureSelectionWidget({
  className,
  selectionModes: selectionModesKeys = Object.values(FEATURE_SELECTION_MODES),
  tooltipPlacement = FeatureSelectionWidgetUI?.defaultProps?.tooltipPlacement,
  id
}) {

  const dispatch = useDispatch();
  const geometry = useSelector((state) => state.carto.spatialFilter);
  const selectedMode = useSelector((state) => state.carto.featureSelectionMode);
  const enabled = useSelector((state) => state.carto.featureSelectionEnabled);
  const selectedWidgetId = useSelector((state) => state.app.selectWidgetId);

  const selectionModes = useMemo(() => {
    return selectionModesKeys
      .filter((key) => FEATURE_SELECTION_MODES_MAP[key])
      .map((key) => ({ id: key, ...FEATURE_SELECTION_MODES_MAP[key] }));
  }, [selectionModesKeys]);

  const handleEnabledChange = (newEnabled) => {
    dispatch(setFeatureSelectionEnabled(newEnabled));
  };

  const handleSelectMode = (newSelectedMode) => {
    if (newSelectedMode === selectedMode && enabled && id === selectedWidgetId) {
      handleEnabledChange(false);
    }else {
    dispatch(setFeatureSelectionMode(newSelectedMode));
    handleEnabledChange(true);
    dispatch(setSelectWidgetId(id));
    }

  };

  const handleSelectGeometry = () => {
  };

  const handleDeleteGeometry = () => {
  };

  return (
    <FeatureSelectionWidgetUI
      id={id}
      className={className}
      selectionModes={selectionModes}
      selectedMode={selectedMode}
      onSelectMode={handleSelectMode}
      enabled={enabled}
      onEnabledChange={handleEnabledChange}
      tooltipPlacement={tooltipPlacement}
      geometry={geometry}
      onSelectGeometry={handleSelectGeometry}
      onDeleteGeometry={handleDeleteGeometry}
    />
  );
}

FeatureSelectionWidget.propTypes = {
  className: FeatureSelectionWidgetUI.propTypes.className,
  selectionModes: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(FEATURE_SELECTION_MODES))
  ),
  tooltipPlacement: FeatureSelectionWidgetUI.propTypes.tooltipPlacement
};

export default FeatureSelectionWidget;
