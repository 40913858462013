import * as React from 'react';
import { Grid } from '@mui/material';
import { useLocation, Outlet } from 'react-router-dom';

import useOrganization from 'api/hooks/useOrganization';
import logo from 'assets/img/logo.0c7a85ba.png';
import { useTheme } from 'components/providers/CustomThemeProvider';
import {
  MuiTitle,
  MuiGridImgContainer,
  MuiImage, MuiContainer,
} from './styles/organization';
import {useAuth} from "../../../providers/AuthProvider";

const Organization = () => {
  const location = useLocation();
  const orgId = location.pathname.split('/')[2];
  const { isAuthenticated } = useAuth();
  const { data, isLoading } = useOrganization({id: orgId, enabled: isAuthenticated, isAuthenticated});
  const { setColors } = useTheme();
  const isSpecificationView = location.pathname.split('/').includes('specification')

  React.useEffect(() => {
    if (data) setColors(data.attrs.primary_color, data.attrs.secondary_color);
  }, [data, setColors]);

  return (
    <MuiContainer>
      <Grid container style={{marginTop: isSpecificationView ? '22px' : '47px'}}>
        {!isSpecificationView && (
          <MuiGridImgContainer item xs={3}>
            <MuiTitle>{data?.name}</MuiTitle>
            {isLoading ? (
              <>
                <MuiImage src={logo} alt={'logo'}/>
              </>
            ) : (
              <>
                <MuiImage src={data?.attrs.profile_img_url} alt={'logo'}/>
              </>
            )}
          </MuiGridImgContainer>
        )}
        <Grid item xs={!isSpecificationView ? 9 : 12}>
          <Outlet />
        </Grid>
      </Grid>
    </MuiContainer>
  );
};

export default Organization;
