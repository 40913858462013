import {Box, Button, Modal, Typography} from "@mui/material";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import {
  MuiBox,
  MuiBoxContainer,
  MuiButton, MuiTypography,
  MuiTypographyAction
} from './styles/updateNotificationModal';
import { useTranslation } from '../providers/TranslationProvider';

export const UpdateNotificationModal = ({
                                          updateAvailable,
                                          setUpdateAvailable,
                                          updatedAppVersion
                                        }) => {
  const { t } = useTranslation();
  const reloadPage = async () => {
    try {
      if ('caches' in window) {
        window.caches.keys().then((keys => {
          keys.forEach(key => {
            window.caches.delete(key);
          })
        }))
      } else {
        console.log("No caches entry in window");
      }
    } catch (e) {
      console.warn("Couldn't clear cache", e);
    }
    localStorage.setItem("appVersion", updatedAppVersion);
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      })
    })
    window.location.reload();
  }

  return (
    <Modal open={updateAvailable} onClose={() => setUpdateAvailable(false)}
           sx={{backgroundColor: 'transparent !important'}}>
      <MuiBoxContainer>
        <MuiBox>
          <BrowserUpdatedIcon
            sx={{color: '#D7271D', width: '24px', height: '24px'}}/>
        </MuiBox>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
          <MuiTypography component={'span'}>
            {t('new_version_available')}
          </MuiTypography>
          <MuiTypographyAction component={'span'}>
            {t('update_latest_version')}
          </MuiTypographyAction>
        </Box>
        <MuiButton
          variant={'text'}
          onClick={async () => await reloadPage()}
        >
          Update
        </MuiButton>
      </MuiBoxContainer>
    </Modal>
  )
}
