/* eslint-disable camelcase */
import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';

const fetchPublicScheme = ({ user_id, scheme_id, isAuthenticated }) =>{
  if (isAuthenticated) {
    return axios.post(apiPaths.publicScenario, { user_id, scheme_id},{headers: {Authorization : `Bearer ${localStorage.getItem('accessToken')}`}}, status200).then((resp) => resp.data);
  }
}

const usePublicScheme = ({ onError, enabled, ...data }) =>
  useQuery(['scheme-public', data.scheme_id], () => fetchPublicScheme(data), {
    enabled,
    onError: () => onError(),
    retry: false,
  });

export default usePublicScheme;
