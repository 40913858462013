import * as React from 'react';
import {
  Grid,
  Box,
  Divider
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import {
  setRecomputedRecommendationsWidgets,
  setWidgetFiltersForCurrentOffer,
  setWidgetFiltersForTargetDemand  }
from 'store/appSlice';

import Modal from 'components/common/Modal';
import Toast from 'components/common/Toast';
import { SliderFieldCustom } from 'components/common/ReactHooksFormFields';
import TypographyWithInfoIconTooltip from 'components/common/TypographyWithInfoIconTooltip';

import { useTranslation } from 'components/providers/TranslationProvider';
import IOSSwitch from 'components/common/IOSSwitch';
import { useDispatch, useSelector } from 'react-redux';
import {
  customSwitchLabelStyle,
  generateFormModalStyle,
  marginStyle,
  MuiBtnAccept,
  MuiBtnCancel,
  MuiContainer,
  MuiSwitchTypography,
  MuiTypography,
  MuiVariableName
} from '../styles/computeRecommendationFormModal';

const ComputeRecommendationsFormModal = ({ open, onClose, defaultValues, dataForOP }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openToast, setOpenToast] = React.useState(false);
  const [error, setError] = React.useState('');
  const selectedWidgetId = useSelector((state) => state.app.selectWidgetId);
  const selectedWidgetEntity = useSelector((state) => ( state.app.recomputedRecommendationsWidgets.find(w => w.id === selectedWidgetId) || state.app.widgets.find(w => w.id === selectedWidgetId )));
  const isCostVisible = selectedWidgetEntity?.params?.fieldsToShow?.isCostVisible === true ? true : selectedWidgetEntity?.params?.fieldsToShow?.isCostVisible === false ? false : true;
  const isOfferCapacityVisible = selectedWidgetEntity?.params?.fieldsToShow?.isOfferCapacityVisible === true ? true : selectedWidgetEntity?.params?.fieldsToShow?.isOfferCapacityVisible === false ? false : true;

  const getDefaultValues = () => {
    return {
      offerCoverageRadius: (selectedWidgetEntity?.params?.coverage || selectedWidgetEntity?.params?.coverage === 0) ? selectedWidgetEntity?.params?.coverage : selectedWidgetEntity?.params.offerCoverageRadius,
      offerCost: ( selectedWidgetEntity?.params?.offer_cost ) ? selectedWidgetEntity?.params?.offer_cost || 1 : selectedWidgetEntity?.params?.offerCost || 1,
      isOfferCapacitySelected: ( selectedWidgetEntity?.params?.is_offer_capacity_selected ) ? selectedWidgetEntity?.params?.is_offer_capacity_selected : selectedWidgetEntity?.params?.isOfferCapacitySelected,
      offerCapacity: ( selectedWidgetEntity?.params?.offer_capacity ) ? selectedWidgetEntity?.params?.offer_capacity || 1 : selectedWidgetEntity?.params?.offerCapacity || 1
    }
  };

  const clearComputedSelectedWidgetFilters = () => {
    dispatch(setWidgetFiltersForTargetDemand( [] ));
    dispatch(setWidgetFiltersForCurrentOffer( [] ));
  }

  const methods = useForm({
    // resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
    mode: 'all',
  });

  const {
    watch,
    setValue,
  } = methods;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const offerCoverageRadius = watch('offerCoverageRadius');
  const offerCost = watch('offerCost');
  const isOfferCapacitySelected = watch('isOfferCapacitySelected');
  const offerCapacity = watch('offerCapacity');


  const recomputeAndUpdateOPData = () => {
    const potentialOffersParams = {
      ...dataForOP,
      offerCoverageRadius,
      offerCost,
      isOfferCapacitySelected,
      offerCapacity
    }
    defaultValues.createPotentialOffers(potentialOffersParams);
    const updatedWidget = {
      id: selectedWidgetEntity.id,
      layer: selectedWidgetEntity.layer,
      params: {
        ...selectedWidgetEntity.params,
      },
    };
    updatedWidget.params.offerCoverageRadius = offerCoverageRadius;
    updatedWidget.params.offerCost = offerCost;
    updatedWidget.params.isOfferCapacitySelected = isOfferCapacitySelected;
    updatedWidget.params.offerCapacity = offerCapacity;
    updatedWidget.params.coverage = offerCoverageRadius;
    updatedWidget.params.offer_cost = offerCost;
    updatedWidget.params.offer_capacity = offerCapacity;
    dispatch(setRecomputedRecommendationsWidgets( updatedWidget ));
    clearComputedSelectedWidgetFilters();

  }

  const formComputeRecommendations = (
    <MuiContainer display='flex'>
      <Toast
        message={error}
        handleClose={handleCloseToast}
        severity='error'
        horizontal='center'
        vertical='top'
        open={openToast}
      />
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex">
          <MuiTypography variant="body1">
            { defaultValues?.customLabels?.coverageRadius || t('coverage_radius') }:
          </MuiTypography>
          <TypographyWithInfoIconTooltip >
            { t('recommendations_offer_coverage_radius') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='offerCoverageRadius'
            defaultValue={200}
            min={200}
            customMax={20000}
            valueLabelDisplay='auto'
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <MuiVariableName variant='caption'>
            (coverage)
          </MuiVariableName>
          </Box>
          {isCostVisible && <Divider style={{ marginTop: 20, marginBottom: 20 }}/>}
      </Grid>
      { isCostVisible && (
        <>
        <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex">
          <MuiTypography variant="body1">
            { defaultValues?.customLabels?.cost || t('cost') }:
          </MuiTypography>
          <TypographyWithInfoIconTooltip >
            { t('recommendations_offer_cost') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid>
      <Grid item xs={12}>
          <Box justifyContent="space-between" display="flex">
          <SliderFieldCustom
            name='offerCost'
            defaultValue={1}
            step={1}
            min={1}
            max={100}
            valueLabelDisplay='auto'
            custom
            gridSliderInputColumn= {5}
            gridSliderColumn= {7}
          />
          <MuiVariableName variant='caption'>
            {`(${ t('unit').toLowerCase()})`}
          </MuiVariableName>

          </Box>
          <Divider style={{ marginTop: 20, marginBottom: 20 }}/>
      </Grid>
        </>
      )}
      { isOfferCapacityVisible && (
      <Grid item xs={12}>
        <Box justifyContent="space-between" display="flex">
          <MuiTypography variant='body1'>
            {t('recommendations_is_offer_capacity_selected')}
          </MuiTypography>
          <IOSSwitch
            name="isOfferCapacitySelected"
            labelPlacement="start"
            labelClasses={ customSwitchLabelStyle }
            checked={isOfferCapacitySelected}
            onChange={(e) => setValue('isOfferCapacitySelected', e.target.checked)}
          />
          <MuiSwitchTypography variant='caption'>
            { t('no_yes') }
          </MuiSwitchTypography>
          <TypographyWithInfoIconTooltip className={ marginStyle }>
            { t('recommendations_offercapacity_tooltip') }
          </TypographyWithInfoIconTooltip>
        </Box>
      </Grid> )}
      { isOfferCapacityVisible && isOfferCapacitySelected  ? (
        <Grid item xs={12}>
          <Box justifyContent="space-between" display="flex" style={{marginTop: 5, width: '100%'}}>
            <SliderFieldCustom
              name='offerCapacity'
              defaultValue={1}
              step={1}
              min={1}
              max={1000}
              valueLabelDisplay='auto'
              custom
              gridSliderInputColumn= {5}
              gridSliderColumn= {7}


            />
          <MuiVariableName variant='caption'>
            (capacity_lower_bound)
          </MuiVariableName>
          </Box>
        </Grid>
      ) : null }
    </MuiContainer>
  );

  const actions = (
    <>
      <MuiBtnCancel onClick={onClose} variant='text'>
        {t('cancel_btn')}
      </MuiBtnCancel>
      <MuiBtnAccept
        type='submit'
        variant='outlined'
        onClick={ recomputeAndUpdateOPData }
      >
        { t('widget_button_generate_label') }
      </MuiBtnAccept>
    </>
  );

  return (
    <FormProvider {...methods}>
      <form>
        <Modal
          open={open}
          title={
            t('compute_recommendations_modal_header')
          }
          actions={actions}
          widthInPixels={360}
          sx={generateFormModalStyle}
        >
          {formComputeRecommendations}
        </Modal>
      </form>
    </FormProvider>
  );
};

export default ComputeRecommendationsFormModal;
