import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLayer,
  addSource,
  setCredentials,
  removeLayer,
  removeSource,
  setViewState,
  setBasemap,
  addFilter,
  addSpatialFilter
} from 'lib/react-geo-tool/packages/react-redux/src/';
import { useLocation, useNavigate } from 'react-router';
import _ from 'lodash';

import useScheme from 'api/hooks/useScheme';
import usePublicScenario from 'api/hooks/usePublicScenario';
import {
  setLayers,
  setPolygonLayers,
  setAllTabs,
  changeLayerVisibility,
  changeRecommendationAreaLayerVisibility,
  changeLayerHide,
  setWidgets,
  setLayerLoadingState,
  setMaxVisibleWidgets,
  setSendMapStateToServer,
  setAccessToken,
  cleanMapInfoBeforeReload,
  setAllowUpgradeOperator,
  setShowActionCatalog,
  setInitialViewPort,
  setShowDownloadLayer,
  setWidgetFoldedState,
  setParametersCatalog,
  setPointsOfReference,
} from 'store/appSlice';

import { useAuth } from './AuthProvider';
import { MAP_TYPES } from "@deck.gl/carto";

const LayersContext = React.createContext();

const LayersProvider = ({ children }) => {
  const [mapLayers, setMapLayers] = React.useState([]);

  const layers = useSelector((state) => state.app.layers);
  const polygonLayers = useSelector((state) => state.app.polygonLayers);
  const widgets = useSelector((state) => state.app.widgets);
  const sources = useSelector((state) => state.carto.dataSources);
  const mapInfoBeforeReload = useSelector((state) => state.app.mapInfoBeforeReload);
  const viewState = useSelector((state) => state.carto.viewState);
  const mapSave = useSelector((state) => state.app.sendMapStateToServer);
  const layerLoadingState = useSelector((state) => state.app.layerLoadingState);
  const pointsOfReference = useSelector((state) => state.app.pointsOfReference);
  const [schemeFetched, setSchemeFetched] = React.useState(null);
  const [mapRefreshCounter, setMapRefreshCounter] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const credentials = useSelector((state) => state.carto.credentials);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = location.pathname.split('/');

  const { user, isAuthenticated } = useAuth();
  const splittedPathname = React.useMemo(() => location.pathname.split('/'), [location.pathname]);
  const id = splittedPathname[2] === 'workspace' ? user.id : splittedPathname[2];
  const org_id = splittedPathname[2];
  const scheme_id = splittedPathname[1] === 'public' ? splittedPathname[4] : splittedPathname[6];
  const isPublicMap = splittedPathname[1] === 'public'

  const {
    data: scheme,
    isSuccess: isSuccessScheme,
    refetch: refetchScheme,
    remove: removeScheme,
  } = useScheme({
    isAuthenticated: isAuthenticated,
    scheme_id: scheme_id,
    user_id: id,
    enabled: false,
  });

  const {
    data: scenario,
    isSuccess: isSuccessScenario,
    refetch: refetchScenario,
    remove: removeScenario,
  } = usePublicScenario({
    isAuthenticated: isAuthenticated,
    scheme_id: splittedPathname[4],
    user_id: id,
    enabled: false,
    onError: () => navigate('/404'),
  });

  const addLayerToMap = React.useCallback(
    (cartoLayers) => {
      cartoLayers.forEach(cartoLayer => {
        if (!mapLayers.some((ml) => ml.id === cartoLayer.id)) {
          const newMapLayers = [...mapLayers, cartoLayer];
          const newMapLayer = _.sortBy(newMapLayers, (l) => l.props.index);
          setMapLayers(newMapLayer);
        }
      });
    },
    [mapLayers]
  );

  const updateLayers = React.useCallback(
    (cartoLayers) => {
      cartoLayers.forEach(cartoLayer => {
        const updatedLayers = mapLayers.map((c) => (c.id === cartoLayer.id ? cartoLayer : c));
        setMapLayers(updatedLayers);
      });
    },
    [mapLayers]
  );

  const removeLayers = React.useCallback(async () => {
    removeScheme();
    removeScenario();
    await layers.forEach((layer) => {
      dispatch(removeLayer(layer.id));
      dispatch(removeSource(layer.id));
    });
    setMapLayers([]);
    dispatch(setLayers([]));
    dispatch(setPolygonLayers([]));
    dispatch(setWidgets([]));
    dispatch(setLayerLoadingState({}));
    dispatch(setSendMapStateToServer(false));
  }, [dispatch, layers, removeScheme, removeScenario]);

  const refreshLayers = React.useCallback(async () => {
    function sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }
    const m = mapLayers;
    await setMapLayers([]);
    await sleep(250);
    await setMapLayers(m);
  }, [mapLayers]);

  const resetLayers = React.useCallback(async () => {
    await removeLayers()
    await refetchScheme()
  }, [removeLayers]);

  const changeVisible = React.useCallback(
    (layerId, visible, isRecommendationA = false , isOfferA = false) => {
      if (visible) {
      } else {
        setMapLayers((prevState) => prevState.filter((cl) => !cl.id.includes(layerId)));
      }
      if (isRecommendationA) {
        dispatch(changeRecommendationAreaLayerVisibility({
          id: layerId + '_r_area',
          visible
        }))
      }
      if (isOfferA) {
        const partsId = layerId.split('_')
        const layer = partsId[0] + '_' + partsId[1] + '_true_co_area'
        dispatch(changeRecommendationAreaLayerVisibility({id: layer, visible}))
      }

      dispatch(changeLayerVisibility({
        id: layerId,
        visible,
        triggeredByUser: true
      }));

    },
    [dispatch]
  );
  const forceHide = React.useCallback(
    (layerId, hide) => {
      if (!hide){
        setMapLayers((prevState) => prevState.filter((cl) => !cl.id.includes(layerId)));
        dispatch(changeLayerHide({ id: layerId, hide }));
      } else{
        dispatch(changeLayerHide({ id: layerId, hide }));
      }
    },
    [dispatch]
  );

  // TODO not dependencies of pathname
  React.useEffect(() => {
    const isMapPublic = splittedPathname[1] === 'public' && splittedPathname[3] === 'custom';

    if (isMapPublic) refetchScenario();

    const isMapView = splittedPathname[4] === 'view' && splittedPathname[5] === 'custom';

    if (isMapView) {
      if (scheme && scheme.access_token) {
        dispatch(setAccessToken(scheme.access_token));
      }
      refetchScheme();
    }
  }, [splittedPathname, refetchScenario, refetchScheme]);

  const dispatchView = React.useCallback(
    (schemeToDispatch, baseMap) => {
      dispatch(setBasemap(baseMap || schemeToDispatch.baseMap));
      if (mapInfoBeforeReload && mapInfoBeforeReload.viewState) {
        dispatch(
          setViewState({
            latitude: mapInfoBeforeReload.viewState.latitude,
            longitude: mapInfoBeforeReload.viewState.longitude,
            zoom: viewState.zoom,
            pitch: 0,
            bearing: 0,
            dragRotate: false,
            transition: 250,
          })
        );

        setTimeout(() => {
          Object.keys(mapInfoBeforeReload.filters).forEach((keyDatasource) => {
            Object.keys(mapInfoBeforeReload.filters[keyDatasource]).forEach((column) => {
              Object.keys(mapInfoBeforeReload.filters[keyDatasource][column]).forEach((filterType) => {
                const filterAttributes = mapInfoBeforeReload.filters[keyDatasource][column][filterType];
                const tempFilter = {
                  id: keyDatasource,
                  column,
                  type: filterType,
                  values: mapInfoBeforeReload.filters[keyDatasource][column][filterType]['values'],
                  owner: mapInfoBeforeReload.filters[keyDatasource][column][filterType]['owner'],
                }
                dispatch(addFilter(tempFilter));
              })
            })
          });
        }, 1500);

      } else if (schemeToDispatch.map_state) {
        const userState = schemeToDispatch.map_state.find((m) => m.owner_id === user?.id)
        if (userState) {
          userState?.widgets?.forEach(element => {
            if (element && element.filters && Object.keys(element.filters).length > 0) {
              const type = element.filters[element.field]?.between ? 'between' : 'in'
              setTimeout(() => {
                const tempFilter = {
                  id: element.layer,
                  column: element.field,
                  type: type,
                  values: element.filters[element.field][type].values,
                  owner: element.filters[element.field][type].owner,
                }
                dispatch(addFilter(tempFilter));
              }, 1500);
            }
            if (element.type === 'parameters' && element.parametersCatalog?.length > 0) {
              dispatch(setParametersCatalog(element.parametersCatalog));
            }
          });
          const view = userState.view ? userState.view : schemeToDispatch.view
          dispatch(
            setViewState({
              latitude: view ? view.latitude ? view.latitude : 17.895245 : 17.895245,
              longitude: view ? view.longitude ? view.longitude : -84.612917 : -84.612917,
              zoom: view ? view.zoom ? view.zoom : 4 : 4,
              pitch: view ? view.pitch : 0,
              bearing: view ? view.bearing : 0,
              dragRotate: view ? view.dragRotate : false,
              transition: view ? view.transition : 0,
            })
          );
          if ( userState.pointsOfReference && userState.pointsOfReference.length > 0 ) {
            dispatch( setPointsOfReference( userState.pointsOfReference ) );
          }
        }
      } else if (schemeToDispatch) {
        dispatch(
          setViewState({
            latitude: schemeToDispatch.view ? schemeToDispatch.view.latitude ? schemeToDispatch.view.latitude : 17.895245 : 17.895245,
            longitude: schemeToDispatch.view ? schemeToDispatch.view.longitude ? schemeToDispatch.view.longitude : -84.612917 : -84.612917,
            zoom: schemeToDispatch.view ? schemeToDispatch.view.zoom ? schemeToDispatch.view.zoom : 4 : 4,
            pitch: 0,
            bearing: 0,
            dragRotate: false,
            transition: 250,
          })
        );
      } else {
        if (mapSave) {
          dispatch(
            setViewState({
              latitude: viewState.latitude,
              longitude: viewState.longitude,
              zoom: viewState.zoom,
              pitch: 0,
              bearing: 0,
              dragRotate: false,
              transition: 250,
            })
          );
        } else {
          dispatch(
            setViewState({
              latitude: schemeToDispatch.view ? schemeToDispatch.view.latitude ? schemeToDispatch.view.latitude : 17.895245 : 17.895245,
              longitude: schemeToDispatch.view ? schemeToDispatch.view.longitude ? schemeToDispatch.view.longitude : -84.612917 : -84.612917,
              zoom: schemeToDispatch.view ? schemeToDispatch.view.zoom ? schemeToDispatch.view.zoom : 4 : 4,
              pitch: 0,
              bearing: 0,
              dragRotate: false,
              transition: 250,
            })
          );
        }
      }
    },
    [dispatch, mapRefreshCounter]
  );

  const dispatchPolygonLayer = React.useCallback(
    (mapId, widgetId, access_token, user_id, list) => {
      const fields = list && list.length > 0 ? `, ${list.map(c => c.field).join(', ')}` : '';
      const sqlQuery = `select geom${fields} from ` +
      "`incluia-project.incluia_datasets.widget_polygon_" + mapId + "_" + widgetId + "`" +
      " WHERE (user_deletions IS NULL OR user_deletions NOT LIKE '%" + user_id + "%') AND (polygon_type = 'from_layer' OR owner_id ='" + user_id + "')";
      dispatch(
        addSource({
          id: widgetId,
          data: sqlQuery,
          datasetName: "incluia-project.incluia_datasets.widget_polygon_" + mapId + "_" + widgetId,
          // Add credential
          connection: 'carto_dw',
          type: MAP_TYPES.QUERY,
          credentials: {
            apiVersion: credentials.apiVersion,
            apiBaseUrl: process.env.REACT_APP_CARTO_PIA_URL,
            accessToken: access_token
          }
        })
      );
      dispatch(setCredentials({
          apiVersion: credentials.apiVersion,
          apiBaseUrl: process.env.REACT_APP_CARTO_PIA_URL,
          accessToken: access_token
        })
      );
      dispatch(
        addLayer({
          id: widgetId,
          source: widgetId,
        })
      );
    },
    [dispatch]
  );


  const dispatchRecommendationLayer = React.useCallback(
    (mapId, widgetId, access_token, column , fixed) => {
      const key =`${widgetId}_${column}_${fixed}`;
      dispatch(
        addSource({
          id: key,
          data: "select geom from " + "`incluia-project.incluia_datasets.user_"+ mapId + '_scenario_' + widgetId + '_recommended_offers` where ' + column + '=' + fixed,
          datasetName: "`incluia-project.incluia_datasets.`user_"+ mapId + '_scenario_' + widgetId + '_recommended_offers`',
          // Add credential
          connection: 'carto_pia',
          type: MAP_TYPES.QUERY,
          credentials: {
            apiVersion: credentials.apiVersion,
            apiBaseUrl: process.env.REACT_APP_CARTO_PIA_URL,
            accessToken: access_token
          },
        })
      );
      dispatch(setCredentials({
          apiVersion: credentials.apiVersion,
          apiBaseUrl: process.env.REACT_APP_CARTO_PIA_URL,
          accessToken: access_token
        })
      );
      dispatch(
        addLayer({
          id: key,
          source: key,
        })
      );
    },
    [dispatch]
  );

  const dispatchCartoLayer = React.useCallback(
    (layer, w = [], access_token) => {
      const getSqlString = () => {
        const layerTooltip = layer.tooltip?.columns?.map((f) => f.field) || [];
        const widgetColum =
          w.filter((i) => i.layer === layer.id && i.visible).map((i) => {
            if(i.type === 'list'){

              let query = i.params.list.map((item) => {
                return item.calculator ? `${item.field} AS ${item.alias}` : `${item.field}`

              }).join(', ');
              return query

            }
            if( i.type !== 'recommendations' && i.params.calculator){
              let query = `${i.params.field} AS ${i.params.alias}`
              return query
            }else if (i.params.field){
              return i.params.field
            }
          }) || [];
        const legendColumn = layer?.datasetColumn;
        return _.union([
          ...layerTooltip,
          ...widgetColum,
          legendColumn,
          'geom'
        ])
          .filter((c) => c !== undefined)
          .join(', ');
      };
      dispatch(
        addSource({
          id: layer.id,
          data: "SELECT " + getSqlString() + " FROM " + "`" + layer.datasetName + "`",
          datasetName: layer.datasetName,
          // Add credential
          connection: 'carto_pia',
          type: MAP_TYPES.QUERY,
          credentials: {
            apiVersion: credentials.apiVersion,
            apiBaseUrl: process.env.REACT_APP_CARTO_PIA_URL,
            accessToken: access_token
          }
        })
      );
      dispatch(setCredentials({
          apiVersion: credentials.apiVersion,
          apiBaseUrl: process.env.REACT_APP_CARTO_PIA_URL,
          accessToken: access_token
        })
      );
      dispatch(
        addLayer({
          id: layer.id,
          source: layer.id,
        })
      );
    },
    [dispatch]
  );

  const isLayerVisible = ( layersState, layerEntity ) => {
    if ( layersState ) {
      if ( layersState[layerEntity.id] && layerEntity.isZoomVisibilityEnabled ) {
        return Math.floor(viewState.zoom) >= layerEntity.visibilityByZoomLevel[0] && Math.floor(viewState.zoom) <= layerEntity.visibilityByZoomLevel[1];
      }
      return layersState[layerEntity.id];
    }
  }

  const initializeMap = React.useCallback(
    (map) => {
      const mState = map.scheme.map_state.find((ms) => ms.owner_id === user.id);
      const globalWidgetsMapState = map.scheme.global_map_state.widgets;
      const layersState = mState.layers.reduce(
        (acc, l) => ({ ...acc, [l.layerId]: l.visible }),
        {}
      );
      const widgetsState = mState.widgets.filter( mapStateWidget => !globalWidgetsMapState.some( gw => gw.id === mapStateWidget.widgetId ));
      const allWidgets = map.scheme.widgets.reduce((acc, w) => ({ ...acc, [w.id]: w }), {});

      const getDynamicRecommendationsName = (isWidgetGlobal) => {
        let dynamicRecommendationsName = '';
        if (isWidgetGlobal || isWidgetGlobal === null || isWidgetGlobal === undefined) {
          dynamicRecommendationsName = map.scheme.id + '_' + org_id;
        } else {
          dynamicRecommendationsName = map.scheme.id + '_' + user.id;
        }
        return dynamicRecommendationsName;
      }

      const objWidgetNotVisible = widgets.filter(ref => ref.visible === false);
      const newWidgets = widgetsState.map((w) => {
        const widgetTemp = mState.recos?.find((wid) => wid.id ===w.widgetId);
        const instanceWidget = objWidgetNotVisible.find(item => item.id === w.widgetId);
        if (widgetTemp) {
          return {
            ...allWidgets[w.widgetId],
            visible: instanceWidget ? instanceWidget.visible : true,
            active_tab: w.active_tab,
            description: w.description,
            folded_state: w.folded_state,
            parametersCatalog: w.parametersCatalog,
            params: {
              ...allWidgets[w.widgetId].params,
              ...widgetTemp,
            },
          };
        } else {
          return {
            ...allWidgets[w.widgetId],
            visible: instanceWidget ? instanceWidget.visible : true,
            active_tab: w.active_tab,
            description: w.description,
            folded_state: w.type === 'histogram' && isPublicMap ? true: w.folded_state,
            parametersCatalog: w.parametersCatalog,
          };
        }
      }).concat(
        globalWidgetsMapState.map( gWmS => {
          const widgetTemp = mState.recos?.find( (wid) => wid.id === gWmS.id );
            return {
              ...allWidgets[gWmS.id],
              visible: gWmS.visible,
              active_tab: gWmS.active_tab,
              description: gWmS.description,
              folded_state: gWmS.folded_state,
              params: {
                ...allWidgets[gWmS.id].params,
                ...widgetTemp || {},
                ...gWmS.params,
              },
              custom: {
                ...gWmS.custom
              }
            }
        }),
      );

      dispatchView(map.scheme, mState.baseMap);
      dispatch(
        setLayers([...map.scheme.layers.map((l, index) => ({
          ...l,
          accessToken: l.access_token,
          geomName: l.geometryName,
          index,
          visible: isLayerVisible(layersState, l),
          hide: isLayerVisible(layersState, l),
          isPolygon: false,
          isRecommendation: false
        })),
          ...map.scheme.widgets.filter((w) => w.type === 'polygon').map((w, index) => {
            return ({
            id: w.id,
            name: w.name,
            color: w.params.layerColor,
            opacity: w.params.layerOpacity,
            legendType: "simple",
            borderColor: w.params.layerBorderColor,
            borderWidth: w.params.layerBorderWidth,
            datasetName: "incluia-project.incluia_datasets.widget_polygon_" + map.scheme.id + "_" + w.id,
            geomName: 'geom',
            index: map.scheme.layers.length + index,
            visible: layersState[w.id]=== undefined? true: layersState[w.id] ,
            tooltip: {},
            visibilityByZoomLevel: [1, 22],
            legend: [],
            geometry: "MultiPolygon",
            access_token: w.params.accessToken,
            geometryName:"geom",
            accessToken: w.params.accessToken,
            hide: layersState[w.id]=== undefined? true: layersState[w.id],
            isPolygon: true,
            isRecommendation: false
          })}),
          ...map.scheme.widgets.filter((w) => w.type === 'recommendations').map((w, index) => {
            const baseIndex = map.scheme.layers.length + map.scheme.widgets.filter((w) => w.type === 'polygon').length + index*2;
            const recommendationProps = {
              id: w.id + '_fixed_false',
              name: w.params.layersConfiguration.recommendedPointLayerName,
              color: w.params.layersConfiguration.recommendedPointLayerColor,
              areaColor: w.params.layersConfiguration.recommendedPointLayerColor,
              borderColor: w.params.layersConfiguration.recommendedPointLayerColor,
              opacity: w.params.layersConfiguration.recommendedPointLayerOpacity > 1 ? w.params.layersConfiguration.recommendedPointLayerOpacity / 100 : w.params.layersConfiguration.recommendedPointLayerOpacity,
              legendType: "simple",
              borderWidth: 1,
              datasetName: "incluia-project.incluia_datasets.user_" + getDynamicRecommendationsName( w.params?.isGlobal ) + '_scenario_' + w.id + '_recommended_offers',
              geomName: 'geom',
              index: baseIndex,
              visible:  w.params.layersConfiguration.recommendedPointLayerVisibility && layersState[w.id + '_fixed_false'] === undefined ? true : layersState[w.id + '_fixed_false'],
              tooltip: {},
              visibilityByZoomLevel: [1, 22],
              legend: [],
              geometry: "Point",
              access_token: w.params.accessToken,
              geometryName:"geom",
              accessToken: w.params.accessToken,
              hide: w.params.layersConfiguration.recommendedPointLayerVisibility && layersState[w.id + '_fixed_false'] === undefined ? true : layersState[w.id + '_fixed_false'],
              isPolygon: false,
              isRecommendation: true,
              iconSize: 25,
              customIcon: w.params.layersConfiguration.recommendedPointLayerIcon,
              isPointDataSet: true
            };
            return recommendationProps;
          }),
        ])
      );
      dispatch(setAllTabs(map.scheme.tabs));
      dispatch(setWidgets(newWidgets));
      dispatch(setAllowUpgradeOperator(map.scheme?.upgradeOperator || false));
      dispatch(setShowActionCatalog(map.scheme?.showActionCatalog || false));
      dispatch(setShowDownloadLayer(map.scheme?.showDownloadLayer || false));
      dispatch(setMaxVisibleWidgets(map.scheme?.max_visible_widgets || 0));
      dispatch(setInitialViewPort(map.scheme.view.viewport ? map.scheme.view.viewport : []));
      map.scheme.layers.forEach((l) => dispatchCartoLayer(l, newWidgets, map.access_token));
      map.scheme.widgets.filter((w) => w.type === 'polygon').forEach((w) => {
        dispatchPolygonLayer(map.scheme.id, w.id, map.access_token, user.id, w.params.list);
      });
      map.scheme.widgets.filter((w) => w.type === 'recommendations').forEach((w) => {
          dispatchRecommendationLayer( getDynamicRecommendationsName( w.params?.isGlobal ), w.id, map.access_token, 'fixed' , true );
          dispatchRecommendationLayer( getDynamicRecommendationsName( w.params?.isGlobal ), w.id, map.access_token, 'fixed' , false );
      });
      setTimeout(() => {
        dispatch(cleanMapInfoBeforeReload());
      }, 8000)
    },
    [dispatch, dispatchCartoLayer, mapRefreshCounter, dispatchView, location.pathname]
  );

  React.useEffect(() => {
    if (isSuccessScenario && scenario?.scheme?.layers) {
      initializeMap(scenario);
      setSchemeFetched(scenario);
    }

    if (isSuccessScheme && scheme?.scheme?.layers) {
      initializeMap(scheme);
      setSchemeFetched(scheme);
    }

    if (scheme && scheme.access_token) {
      dispatch(setAccessToken(scheme.access_token));
    }
  }, [isSuccessScheme, isSuccessScenario, scheme, scenario]);

  React.useEffect(() => {
    async function refreshDataCustom() {
      // dispatch(removeLayersFromRedux());
      initializeMap(schemeFetched);
      setRefresh(false);

      if (scheme && scheme.access_token) {
        dispatch(setAccessToken(scheme.access_token));
      }
    }
    if (refresh) {
      setTimeout(refreshDataCustom, 100);
    }
  }, [refresh]);

  React.useEffect(() => {
    if ( pointsOfReference.length > 0 && !urlParams.includes('view') ) {
      dispatch(
        setPointsOfReference( [] )
      );
    }
  }, [location]);

  const value = React.useMemo(
    () => ({
      layers,
      polygonLayers,
      widgets,
      getLayers: mapLayers,
      removeLayers,
      addLayerToMap,
      changeVisible,
      updateLayers,
      refreshLayers,
      forceHide,
      layerLoadingState,
      setRefresh,
      setSchemeFetched,
      mapRefreshCounter,
      setMapRefreshCounter,
      setMapLayers,
      resetLayers,
      sources
    }),
    [
      layers,
      polygonLayers,
      widgets,
      mapLayers,
      removeLayers,
      addLayerToMap,
      changeVisible,
      updateLayers,
      refreshLayers,
      forceHide,
      layerLoadingState,
      setRefresh,
      setSchemeFetched,
      mapRefreshCounter,
      setMapRefreshCounter,
      setMapLayers,
      sources
    ]
  );

  return <LayersContext.Provider value={value}>{children}</LayersContext.Provider>;
};

export const useLayers = () => React.useContext(LayersContext);
export default LayersProvider;
