import { Box, Button, styled, Typography } from '@mui/material';

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '458px',
  border: '0px solid #FFF',
  borderRadius: '12px 0px 12px 12px',
  backgroundColor: '#fff',
  position: 'absolute',
  top: '68px', // Adjust the distance from the top
  right: '5px', // Adjust the distance from the right
  boxShadow: 'none', // Optional: Add a shadow for better visibility
  zIndex: 1300, // Ensure it stays above other elements
  padding: '12px 16px 12px 16px',
  gap: '8px',
  alignItems: 'center',
  opacity: 1,
  animation: `slideInFromRight 0.5s ease-out`,
  '@keyframes slideInFromRight': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
}));

export const MuiBox= styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '40px',
  height: '40px',
  borderRadius: '20px',
  backgroundColor: '#FFECEE',
  justifyContent: 'center'
}));

export const MuiTypography = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: '#424242',
  fontFamily: 'Raleway',
  lineHeight: '16px',
  fontWeight: 500,
  letterSpacing: '0.001em'
}));

export const MuiTypographyAction = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: '#424242',
  fontFamily: 'Montserrat',
  lineHeight: '17px',
  fontWeight: 400,
  letterSpacing: '0.0025em'
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  height: '37px',
  padding: '10px 16px',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  letterSpacing: '0.0025em',
  textTransform: 'uppercase'
}));
