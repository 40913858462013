import * as React from 'react';
import {
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid, Typography, Box
} from '@mui/material';
import DateRangePicker from 'components/common/DateRangePicker.js';

// Style and asset imports
import {
  demoContainerStyle,
  fuenteStyle,
  gridStyle, MuiAlarmOffIcon,
  MuiButtonSkeleton, MuiDatePicker, MuiDeleteIcon, MuiEditIcon, MuiForwardIcon,
  MuiGridContainerButtons, MuiSettingIcon,
  MuiTableBase, MuiTextField, rowFilteredStyle, tableCellActionsStyle, cellStyle
} from './styles/table';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DemoContainer} from '@mui/x-date-pickers/internals/demo';
import {useTranslation} from "../../../providers/TranslationProvider";


const TableSkeleton = ({ columns }) => {
  const rowSkeleton = Array.from(Array(4).keys());

  return (
    <MuiTableBase aria-label='simple table'>
      <TableHead>
        <TableRow>
          {columns.map((c) => (
            <TableCell key={c} component='th' scope='row'>
              {c}
            </TableCell>
          ))}
          <TableCell component='th' scope='row'></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rowSkeleton.map((r) => (
          <TableRow key={r}>
            {columns.map((c) => (
              <TableCell key={c} component='th' scope='row'>
                <MuiButtonSkeleton variant='text' animation='wave' />
              </TableCell>
            ))}
            <TableCell align='right'>
              <MuiGridContainerButtons container>
                <MuiButtonSkeleton variant='text' animation='wave'/>
              </MuiGridContainerButtons>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTableBase>
  );
};

const Table = ({
  columns,
  row,
  data,
  onDelete,
  showDelete,
  onEdit,
  showEdit,
  onForward,
  showSettings,
  onSettings,
  isLoading,
  handleSearch,
  searchTerm,
  setDateRange,
  isDataset = false,
  isUser = false
}) => {
  const [disableFinalDate, setDisableFinalDate] = React.useState(true)
  const [initDateSelected, setInitDateSelected] = React.useState(null);
  const [endDateSelected, setEndDateSelected] = React.useState(null);
  const [clearDate, setClearDate] = React.useState(false);
  const [toggleClearDate, setToogleClearDate] = React.useState(false);
  const { t } = useTranslation();

  const handleStartDate = (date) => {
    setInitDateSelected(date);
    const firstDate = new Date(date);
    if (!isNaN(firstDate)) {
      localStorage.setItem('initDate', firstDate.toLocaleDateString('es-ES'))
      setDisableFinalDate(false)
    }
  };

  const handleEndDate = (date) => {
    setEndDateSelected(date)
    setToogleClearDate(true)
    const endDate = new Date(date);
    let range;
    if (!isNaN(endDate)) {
      const endDateFormated = endDate.toLocaleDateString('es-ES');
      range = {
        initDate: localStorage.getItem('initDate'),
        endDate: endDateFormated,
      }
      setDateRange(range);
    }
  };

  const isCreated = (c) => ['Created', 'Creado'].includes(c);

  const handleRangeChange = (range) => {
    if (range.startDate && range.endDate) {
      const startDateFormatted = range.startDate
        .toDate()
        .toLocaleDateString('es-ES');
      const endDateFormatted = range.endDate
        .toDate()
        .toLocaleDateString('es-ES');

      const createdRange = {
        initDate: startDateFormatted,
        endDate: endDateFormatted,
      }
      setDateRange(createdRange);
    } else {
      setDateRange('');
    }
  };

  const dateRangePicker = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']} sx={demoContainerStyle}>
          <div style={{ display: 'flex' }}>
            <DateRangePicker onChange={handleRangeChange} />
          </div>
        </DemoContainer>
      </LocalizationProvider>
    );
  }

  React.useEffect(() => {
    if (clearDate) {
      setInitDateSelected(null);
      setToogleClearDate(false);
      setClearDate(false);
      setDisableFinalDate(true);
      setDateRange('');
    }
  }, [clearDate]);

  const getWidht = (c) => {
    if (isDataset && c === t('created_at')) {
      return {width: '120px', marginLeft: '30px !important'}
    }
    if (isDataset && c === t('dependencies')?.slice(0, 3)) {
      return {width: '36px'}
    }
    if (isDataset && c === t('type')) {
      return {width: '66px'}
    }
    if (isDataset && c === t('name')) {
      return {width: '150px'}
    }
    if (isDataset && c === t('local_filename')) {
      return {width: '130px'}
    }
    
    if (isDataset && c === t('actions')) {
      return {width: '55px'}
    }
    return {}
  }

  return isLoading ? (
    <TableSkeleton columns={columns} />
  ) : (
    <MuiTableBase aria-label='simple table' sx={isDataset? {width: '100%', tableLayout: 'fixed'}: {}}>
      <TableHead>
        <TableRow>
          {columns.map((c, index) => {
            if (index !== columns.length - 1) {
              return (
                <TableCell key={c} component='th' scope='row' sx={{...getWidht(c), paddingLeft: 0}}>
                  <Typography variant='caption'
                              sx={fuenteStyle}>{c}</Typography>
                </TableCell>
              )
            } else {
              return (
                <TableCell key={c} component='th' scope='row' sx={{ ...getWidht(c), textAlign: 'center'}}>
                  <Typography variant='caption' sx={fuenteStyle}>{c}</Typography>
                </TableCell>
              )
            }
          })}
        </TableRow>
      </TableHead>
      <TableHead>
        <TableRow>
          {columns.map((c, index) => {
            if (index !== columns.length-1) {
              return (
                <TableCell key={c} component='th' scope='row' sx={rowFilteredStyle}>
                  {isCreated(c) ? (dateRangePicker()) :
                    (<MuiTextField
                      key={`start-date-${c}`}
                      placeholder={c}
                      value={searchTerm[index]||""}
                      onChange={(e) => handleSearch(e, index)}
                      type='search'
                      variant='outlined'
                      size='small'
                      sx={{ width: isDataset || isUser ? '90%' : 'auto' }}
                      />
                    )}
                </TableCell>
              );
            }else{
              return (
                <TableCell key={c} component='th' scope='row' sx={{ height: '43px !important' }}>
                </TableCell>
              );
            }
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((r) => (
          <TableRow key={r._id || r.id || r.collection} data-cy='table_common_row'>
            {row.map((j) => (
              <TableCell key={j} sx={j === 'createdAt'? {width: 250,...cellStyle}: {...cellStyle}}>{ j === 'createdAt' && !r[j]? <MuiAlarmOffIcon/> :
                <Typography variant='caption' sx={fuenteStyle} >{r[j]}</Typography>}
              </TableCell>
            ))}
            <TableCell sx={tableCellActionsStyle}>
              <MuiGridContainerButtons container sx={{ justifyContent: 'center'}}>
                {onForward && (
                  <>
                    <Grid item style={gridStyle}>
                      <MuiForwardIcon
                        onClick={() => onForward(r.id, 'old', r._id)}
                        title='Old Tree View'
                        data-cy='table_common_goto_old_tree_action'
                      />
                    </Grid>
                    <Grid item>
                      <MuiForwardIcon
                        onClick={() => onForward(r.id, 'new', r._id)}
                        title='Go to Tree View'
                        data-cy='table_common_goto_new_tree_action'
                      />
                    </Grid>
                  </>
                )}
                {onEdit && (
                  <Grid item>
                    {showEdit && (
                      <MuiEditIcon
                        onClick={(event) => onEdit(r.id || r._id || r.collection, r, event)}
                      />
                    )}
                  </Grid>
                )}
                {onSettings && (
                  <Grid item >
                    {showSettings && (
                      <MuiSettingIcon
                        onClick={() => onSettings(r.id || r._id, r)}
                      />
                    )}
                  </Grid>
                )}
                {onDelete && (
                  <Grid item >
                    {showDelete && (
                      <MuiDeleteIcon
                        onClick={() => onDelete(r.id || r.name || r._id, r)}
                      />
                    )}
                  </Grid>
                )}
              </MuiGridContainerButtons>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </MuiTableBase>
  );
};

export default Table;
