import React, { useState } from 'react';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Box, styled, TextField, IconButton, Popover } from '@mui/material';
import { lighten } from '@mui/material/styles';
import {
  Today as TodayIcon,
  HighlightOff as CancelIcon,
} from '@mui/icons-material';
import { 
  dateRangeInput,
  dateCalendarStyles,
  commonIcon,
} from 'components/common/styles/dateRangePicker';
import { useTranslation } from '../providers/TranslationProvider';

const StyledDay = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isInRange' && prop !== 'isStart' && prop !== 'isEnd' && prop !== 'isInCurrentMonth',
})(({ theme, isInRange, isStart, isEnd, isInCurrentMonth }) => ({
  position: 'relative',
  backgroundColor: isStart || isEnd
    ? lighten(theme.palette.primary.main, 0.5)
    : undefined,
  color: isStart || isEnd || isInRange ? theme.palette.common.white : isInCurrentMonth ? '#616161' : '#9E9E9E',
  borderRadius: isStart || isEnd ? '50%' : '0%',
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  cursor: 'pointer',
  zIndex: isStart || isEnd ? 2 : 1,
  fontSize: '15px',
  fontWeight: '600 !important',
  '&:before': isInRange
    ? {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: '-85%',
        right: '-85%',
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
        zIndex: -1,
      }
    : undefined,
  '& span': {
    position: 'relative',
    zIndex: 3,
  },
}));

const DateRangePicker = ({ onChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  const handleDayClick = (date) => {
    if (!isSelecting) {
      setStartDate(date);
      setEndDate(null);
      setIsSelecting(true);

      if (onChange) {
        onChange({ startDate: date, endDate: null });
      }

    } else {
      if (dayjs(date).isBefore(startDate)) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }
      setIsSelecting(false);

      if (onChange) {
        onChange({
          startDate: dayjs(date).isBefore(startDate) ? date : startDate,
          endDate: dayjs(date).isBefore(startDate) ? startDate : date,
        });
      }
    }
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    if (onChange) {
      onChange({ startDate: null, endDate: null });
    }
  };

  const isInRange = (date) => {
    if (startDate && endDate) {
      return dayjs(date).isAfter(startDate) && dayjs(date).isBefore(endDate);
    }
    return false;
  };

  const CustomDay = (props) => {
    const { day, currentMonth } = props;
    const isStart = startDate && dayjs(day).isSame(startDate, 'day');
    const isEnd = endDate && dayjs(day).isSame(endDate, 'day');
    const isRange = isInRange(day);
    const isInCurrentMonth = dayjs(day).isSame(currentMonth, 'month');

    return (
      <StyledDay
        isStart={isStart}
        isEnd={isEnd}
        isInRange={isRange}
        isInCurrentMonth={isInCurrentMonth}
        onClick={() => handleDayClick(day)}
      >
        {dayjs(day).date()}
      </StyledDay>
    );
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleMonthChange = (newMonth) => {
    setCurrentMonth(newMonth);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
      <TextField
          label={ t('date_range_initial_end') }
          value={
            startDate && endDate
              ? `${dayjs(startDate).format('DD/MM/YYYY')} - ${dayjs(endDate).format('DD/MM/YYYY')}`
              : ''
          }
          sx={ dateRangeInput }
          readOnly
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={startDate && endDate ? handleClear : handleOpen}
                disableRipple
                sx={{ paddingRight: 0 }}
              >
                { startDate && endDate ? <CancelIcon sx={commonIcon} /> : <TodayIcon sx={commonIcon} /> }
              </IconButton>
            ),
          }}
        />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: '8px',
                boxShadow: '2px 16px 19px 0px #00000017',
              },
            },
          }}
        >
          <Box sx={{ padding: '10px' }}>
            <DateCalendar
              slots={{
                day: (props) => (
                  <CustomDay {...props} currentMonth={currentMonth} />
                ),
              }}
              views={['day']}
              sx={dateCalendarStyles}
              dayOfWeekFormatter={(weekday) => `${weekday.format('ddd').toUpperCase()}`}
              onMonthChange={handleMonthChange}
            />
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;