// External library imports
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

// Internal component and function imports
import EditIcon from '../../common/EditIcon';
import {
  styled,
  Grid,
  Container,
  Toolbar,
  Tab,
  Typography,
  Table,
  TablePagination,
  Skeleton,
   TextField
} from '@mui/material';
import { GLOBAL } from '../../../../../utils/constants';

export const MuiTab = styled(Tab)(({ theme }) => ({
  minWidth: 'auto',
  paddingLeft: theme.spacing(1),
  marginLeft: theme.spacing(3),
}));

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  backgroundColor: '#FAFAFA',
  minHeight: '50px !important',
  marginTop: theme.spacing(3),
}));

export const MuiGridBox = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(0),
}));

export const MuiGridImgContainer = styled(Grid)(({ theme }) => ({
  textAlign: 'center',
}));

export const MuiImage  = styled("img")(({ theme }) => ({
  width: 248,
  backgroundColor: 'white',
  height: 248,
  borderRadius:'19px',
  border:'3px solid',
  borderColor: theme.palette.primary.main,
  objectFit: 'scale-down',
}));

export const MuiGridContainer = styled(Grid)(({ theme }) => ({
  paddingTop: 10,
}));

export const MuiTitle = styled(Typography)(({ theme }) => ({
  fontSize: 29,
  fontWeight:700,
  marginRight:theme.spacing(6),
  marginBottom:theme.spacing(2),
  fontStyle:'normal',
  fontFamily:'Raleway',
  color:'#212121',
  textAlign:'right'
}));

export const MuiContainer = styled(Container)(({ theme }) => ({
  boxShadow: 'none',
  overflow: 'visible',
  marginBottom: 40,
  maxWidth: '1400px !important'
}));

export const MuiTableBase = styled(Table)(({ theme }) => ({
  minWidth: 650,
}));

export const MuiGridContainerButtons = styled(Grid)(({ theme }) => ({
  minWidth: 60,
  maxWidth: '100%',
  justifyContent: 'flex-start',
}));

export const MuiButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 60,
}));

export const MuiEditIcon = styled(EditIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  minWidth: 25,
  padding: 1,
  border: '1px solid',
  borderRadius:'50%',
  align: 'center',
  cursor:'pointer',
}));

export const MuiDeleteIcon = styled(DeleteOutlinedIcon)(({ theme }) => ({
  color: '#F63B2B',
  minWidth: 25,
  padding: 1,
  borderRadius:'50%',
  border: '1px solid',
  align: 'center',
  cursor:'pointer'
}));

export const MuiAlarmOffIcon = styled(AlarmOffIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  minWidth: 25,
  padding: 1,
  marginLeft: theme.spacing(3),
  align: 'center',
}));

export const MuiTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(-0.5),
  marginBottom: theme.spacing(0),
  marginLeft:'-10px',
  '& .MuiInputBase-root': {
    height: '45px',
    padding: '5px 8px 5px 8px',
    borderRadius:'4px',
    borderColor:'#757575'
  },
  '& input::placeholder': {
    fontSize: '14px',
    textTransform: 'uppercase',
    fontFamily:'Montserrat',
  },
}));

export const MuiTablePagination = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiSelect-select': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiTablePagination-displayedRows': {
    fontSize:12,
    fontFamily:'Montserrat',
  },'& .MuiToolbar-root': {
    paddingLeft:'0px',
  }
}));

export const tableCellActionsStyle = {
  padding:0,
  paddingRight:2
}

export const gridStyle = {
  display: 'none'
}

export const gridContainerStyle = {
  paddingTop: 20,
}

export const toolBarStyle = {
  paddingLeft: '55vh',
  backgroundColor: 'white'
};

export const actionsComponentStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight:'650px'
}
export const MuiBoxTab= styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
