import logoIncluia from '../../../../assets/img/prosperia_4.png'
import {
  Box,
  Button,
  Grid,
  IconButton,
  styled,
  Typography
} from '@mui/material';

export const MuiDivPaper = styled('div')(({ theme }) => ({
  width:'327px',
  margin: '106px 116px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const MuiDivLayout = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  backgroundImage: `url(${logoIncluia})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  width: '100%',
  minWidth: '100%',
}));

export const MuiTypographyForgot = styled(Typography)(({ theme }) => ({
  fontFamily: 'Raleway',
  fontSize: '25px',
  fontWeight: 700,
  textAlign: 'start',
}));

export const MuiLoginText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: '45px',
  color: 'rgba(0, 187, 212, 1)',
  fontFamily: 'Raleway',
  fontWeight:600,
  textTransform:'uppercase'
}));

export const MuiLoginSubtitle = styled(Typography)(({ theme }) => ({
  maxWidth:'100%',
  fontSize: '21px',
  fontFamily: 'Raleway',
  fontWeight:500,
  width: '100%',
  textAlign: 'center',
  lineHeight: '20px',
  marginBottom: '38px'
}));

export const MuiForgotContent = styled(Typography)(({ theme }) => ({
  width: '328px',
  fontSize:'12px',
  fontFamily: 'Montserrat',
  textAlign:'justify',
  marginTop: '17px'
}));

export const MuiBox = styled(Box)(({ theme }) => ({
  display:'flex',
  justifyContent: 'flex-start',
  width: '100%',
  marginBottom: '28px'
}));

export const MuiButtonSubmit = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isValid' && prop !== 'loading'
})(({ theme,isValid }) => ({
  backgroundColor: !isValid ? 'rgba(0, 187, 212, .5)':'rgba(0, 187, 212, 1)',
  borderRadius: '20px',
  height: '40px',
  color: 'white !important',
  fontSize: '14px',
  fontFamily:'Montserrat',
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: 'rgba(0, 150, 170, 1)',
  },
}));

export const MuiButtonForgot = styled(Button)(({ theme }) => ({
  color: 'rgba(103, 147, 165, 1)',
  fontSize: '12px',
  fontFamily:'Montserrat',
  cursor: 'pointer',
  marginLeft: '4px',
  padding: theme.spacing(0),
  textTransform: 'none',
  fontWeight: 400,
  marginTop: '24px',
}));

export const MuiGridEmail = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom:'0px !important',
  paddingTop:'12px !important'
}));

export const MuiLoginGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const MuiGridLayout = styled(Grid)(({ theme }) => ({
  height: '100vh',
  justifyContent: 'space-between',
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  color: '#6793A5'
}));

export const MuiGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',
  marginTop: '28px',
  display: 'flex',
  justifyContent:'end',
  marginRight: '119px',
}));

export const orStyle = {
  marginTop:'0px',
  marginBottom:'18px',
};

export const orTextStyle = {
  fontFamily: 'Raleway',
  fontSize: '16px',
};

export const containerButtonsStyle = {
  display: 'flex',
  gap: '12px',
};

export const socialIcon = {
  position: 'absolute',
  left: '12px',
  top: '8px'
};

export const loginField = {
  border: '5px',
  width: '312px',
  height: '56px',
  '& .MuiInputLabelRoot':{
    fontFamily:'Montserrat'
  },
  '& .MuiInputBase-input::placeholder': {
    fontFamily: 'Montserrat',
  },
  '& .MuiFormHelperText-root': {
    fontFamily: 'Montserrat',
  },
  '& .MuiOutlinedInput-input': {
    fontFamily: 'Montserrat',
  }
};

export const containerButtonsError = {
  display: 'flex',
  marginTop: '36px',
};

export const socialButton = {
    textTransform: 'none',
    color: '#212121',
    border: '1px solid #6793A5',
    "&:hover": {
      backgroundColor: '#F6FEFF',
      border: '1px solid #6793A5',
    },
    paddingY: '12px 64px',
    marginBottom: '14px',
    display: 'flex',
    borderRadius: '20.5px',
    width: '327px',
    height: '40px',
    fontSize: '13px',
    fontFamily: 'Montserrat',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  };

  export const errorMessage = {
    color: '#d32f2f',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
    margin: '10px 14px 0px 14px',
  };

  export const resetNewPassword = {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28.18px',
    marginBottom: '16px',
  }

  export const resetConfirmationPassword = {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16.39px',
    letterSpacing: '0.004em',
    marginBottom: '18px',
  }
