import { GLOBAL } from 'utils/constants';
import {styled, Toolbar, Tabs} from '@mui/material';
import { lighten } from '@mui/material/styles';

export const MuiToolbar = styled(Toolbar)(({ theme }) => ({
  margin: '0',
  width: '100%',
  zIndex: 1201,
}));

export const nonSelectedTab = {
  color: `${GLOBAL.CUSTOM_GRAY_COLOR_PALETTE[800]} !important`,
};

export const MuiTabs = styled(Tabs)(({ theme }) => ({
  padding: 0,
  margin: 0,
  '& .MuiTabs-scrollButtons': {
    width: '24px'
  }
}));


export const muiTab = {
  minWidth: 'auto',
  paddingLeft: '10px',
  paddingRight: '10px',
  '&:hover': {
    backgroundColor: GLOBAL.CUSTOM_GRAY_BLUE_COLOR_PALETTE[200],
    transition: 'background-color 0.3s',
    borderRadius: '12px 12px 0px 0px',
  },
}

export const tabStyle = {
  minWidth: '25%',
  fontWeight: 500,
  fontSize: 14,
  fontFamily:'Montserrat',
}
