import * as React from 'react';

import axios from '../../api/axios/axiosInstance';
import axiosCartoInstance from "../../api/axios/axiosCartoInstance";
import {useAuthCartoPia} from "components/providers/CartoPiaAuthProvider";

// eslint-disable-next-line import/no-cycle
import {useAuth} from '../providers/AuthProvider';

// * Maybe we need more than one axios instance
const axiosInstances = [axios];
const axiosCartoInstances = axiosCartoInstance;

const TokenInterceptor = ({children, accessToken: getAccessToken}) => {
  const {logout, isAuthenticated, isPending} = useAuth();
  const {getCartoPiaAuthenticationToken} = useAuthCartoPia();

  React.useEffect(() => {
    const addResponseInterceptor = (axiosIns) =>
      axiosIns.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          const shouldRefreshToken = isAuthenticated && error.response?.status === 401;
          if (shouldRefreshToken) {
            return logout();
          }
          return Promise.reject(error.response || error);
        }
      );

    const errorInterceptors = axiosInstances.map((instance) => addResponseInterceptor(instance));

    return () => {
      axiosInstances.forEach((instance, i) => {
        instance.interceptors.response.eject(errorInterceptors[i]);
      });
    };
  }, [isAuthenticated, logout]);

  React.useEffect(() => {
    const addRequestInterceptor = (axiosInstance) =>
      axiosInstance.interceptors.request.use(
        (config) => {
          return {
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${getAccessToken}`,
            },
          };
        },
        (error) => Promise.reject(error)
      );

    const authInterceptors = axiosInstances.map((instance) => {
      return addRequestInterceptor(instance);
    });

    return () => {
      axiosInstances.forEach((instance, i) =>
        instance.interceptors.request.eject(authInterceptors[i])
      );
    };
  }, [getAccessToken]);

  React.useEffect(() => {
    const cartoRequestInterceptors = axiosCartoInstances.interceptors.request.use((config) => {
      const newConfig = {...config}
      const cartoPiaAuthenticationToken = getCartoPiaAuthenticationToken();
      newConfig.headers = {
        ...config.headers,
        Authorization: `Bearer ${cartoPiaAuthenticationToken}`
      }
      return newConfig;
    });
    return () => {
      axiosCartoInstances.interceptors.request.eject(cartoRequestInterceptors)
    }

  }, [getAccessToken])

  return  isPending ? null : children;
};

export default TokenInterceptor;
