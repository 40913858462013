export const dateRangeInput = {
    height: '28px',
    width: '190px',
    fontFamily: 'Montserrat',
    '& .MuiInputBase-root': {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '8px 8px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '0',
      fontSize: '10px',
      lineHeight: '1.2',
      textAlign: 'left',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px',
      lineHeight: '1.2',
      transform: 'translate(14px, 8px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      minHeight: '28px',
    },
}

export const dateCalendar = {
    '& .MuiDayCalendar-weekDayLabel': {
      fontSize: '10px !important',
      width: '30px',
      height: '30px',
    },
}

 export const dateCalendarStyles = {
  height: '315px',
  '& .MuiDayCalendar-weekDayLabel': {
    fontSize: '10px',
    width: '30px',
    height: '30px',
    margin: '0px 8px',
    marginBottom: '22px',
    fontWeight: 600,
    color: '#B5BEC6',
  },
  '& .MuiDayCalendar-weekContainer': {
    marginBottom: '8px',
    marginTop: 0,
  },
  '& .MuiPickersArrowSwitcher-root': {
    width: '56px',
    height: '16px',
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
      fill: '#B5BEC6',
    },
  },
  '& .MuiPickersArrowSwitcher-button:hover': {
          backgroundColor: 'transparent',
  },
  '& .MuiPickersCalendarHeader-switcher': {
    height: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiPickersCalendarHeader-root': {
    paddingLeft: '9px',
    marginTop: 0,
  },
};

export const commonIcon = {
  width: '18px',
  height: '18px',
  fill: '#9E9E9E'
}