import React, {useState} from "react";
import {useTranslation} from "../../../providers/TranslationProvider";
import { Box, Button, Typography, Popover } from "@mui/material";
import {btnCancel} from "./DeleteIsochroneModal";
import { SliderFieldCustomTwo } from "../../ReactHooksFormFields";
import {FormProvider, useForm} from "react-hook-form";
import {
  MuiBoxBtnContainer,
  MuiBoxContainer, MuiDrawIcon, MuiPinDrop, MuiSystemUpdateAlt,
  MuiToggleButtonDrawn,
  MuiToggleButtonFromLayer,
  MuiToggleButtonGroup,
  MuiToggleButtonIsochrone,
  stylePopover,
  MuiButton, MuiBox
} from '../styles/filterPopoverPolygon';

const FilterPopoverPolygon =  ({open, min , max ,onClose, handleFilter, anchorEl}) => {
  const { t } = useTranslation();
  const [polygonType, setPolygonType] = useState(['isochrone','drawn','from_layer']);

  const handleClose = () => {
    onClose(false);
  };
  const handlePolygonChange = (event, newSelection) => {
    setPolygonType(newSelection);
  };
  const formMethods = useForm();
  const submitFilter = () => {
    const formData = formMethods.getValues();
    handleFilter({ polygonType, areaRange: formData.area });
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      sx={stylePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <FormProvider {...formMethods}>
      <Box sx={{ml: '16px' , mt: '16px'}}>
        <Typography variant="h6">
          {t('filter_by')}
        </Typography>
        <MuiBoxContainer>
          <Typography variant='caption' sx={{fontFamily: 'Montserrat', mr:1}}>{t('polygon_type')}</Typography>
          <MuiBox>
              <MuiToggleButtonGroup
                value={polygonType}
                onChange={handlePolygonChange}
              >
                <MuiToggleButtonIsochrone value="isochrone">
                  <MuiPinDrop/>
                </MuiToggleButtonIsochrone>
                <MuiToggleButtonDrawn value="drawn">
                  <MuiDrawIcon/>
                </MuiToggleButtonDrawn>
                <MuiToggleButtonFromLayer value="from_layer">
                  <MuiSystemUpdateAlt/>
                </MuiToggleButtonFromLayer>
              </MuiToggleButtonGroup>
          </MuiBox>
        </MuiBoxContainer>
          <Box>
            <Typography variant='caption' sx={{fontFamily: 'Montserrat'}} >
              {t('area')}
            </Typography>
              <SliderFieldCustomTwo
                name='area'
                defaultValue={[min , max]}
                step={1}
                min={min}
                max={max}
                valueLabelDisplay='auto'
              />
          </Box>
      </Box>
      <MuiBoxBtnContainer>
        <MuiButton onClick={handleClose}> {t('cancel')}</MuiButton>
        <MuiButton variant="outlined" color="primary" onClick={submitFilter}>{t('filter')}</MuiButton>
      </MuiBoxBtnContainer>
      </FormProvider>
    </Popover>
  )

}
export default FilterPopoverPolygon;
