import React from 'react';

import { CssBaseline, Grid } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {useLocation} from "react-router-dom";

import Header from 'components/common/Header';
import TranslationProvider from 'components/providers/TranslationProvider';
import CustomThemeProvider from 'components/providers/CustomThemeProvider';
import LayersProvider from 'components/providers/LayersProvider';
import AuthProvider from 'components/providers/AuthProvider';
import Views from 'components/common/Views';
import './index.css';
import { UpdateNotificationModal } from './components/common/UpdateNotificationModal';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: (attemptIndex = 3) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

const gridAppStyles = {
    flex: 1,
    overflow: 'hidden',
    backgroundColor: '#FFFFFF'
};

const App = () => {
  const [isUpdateAvailable, setIsUpdateAvailable] = React.useState(false);
  const [appVersion, setAppVersion] = React.useState("");

  const location = useLocation();
  const NoHeaderRoutes = ["/login", "/reset", "/forgot-password", "/"];
  const showHeader = !NoHeaderRoutes.includes(location.pathname);

  React.useEffect(() => {
    // Fetch the current version from the server
    fetch("/version.json")
      .then((res) => res.json())
      .then((data) => {
        const currentVersion = localStorage.getItem("appVersion");

        if (localStorage.getItem("user") !== null && currentVersion !== data.version) {
          // Notify user about the update
          setIsUpdateAvailable(true);
          setAppVersion(data.version);
        } else {
          setAppVersion(data.version);
          localStorage.setItem("appVersion", data.version);
        }

      })
      .catch((err) => console.error("Failed to fetch version:", err));
  }, [window.location.href]);

  return (
    <CustomThemeProvider>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <TranslationProvider>
          <AuthProvider>
              <Grid container direction='column' style={gridAppStyles}>
                {showHeader && <Header />}
                <div style={{marginTop:showHeader ? 64 : 0}}>
                  <LayersProvider>
                    <Views />
                  </LayersProvider>
                </div>
                <UpdateNotificationModal updateAvailable={isUpdateAvailable}
                                         setUpdateAvailable={setIsUpdateAvailable}
                                         updatedAppVersion={appVersion}/>
              </Grid>
              <ReactQueryDevtools initialIsOpen />
          </AuthProvider>
        </TranslationProvider>
      </QueryClientProvider>
    </CustomThemeProvider>
  );
};

export default App;
