import { blueGrey } from '@material-ui/core/colors';
import { GLOBAL } from 'utils/constants';
import {
  Card,
  CardActionArea,
  CardMedia, IconButton,
  Link,
  styled,
  Typography
} from '@mui/material';
import logoIncluia from '../../../../assets/img/prosperia_4.png';
import CustomSkeleton from '../../../common/CustomSkeleton';

export const MuiCard = styled(Card)(({ theme }) => ({
  width: 251,
  height: 305,
  borderRadius: 19,
  backgroundColor: '#f5f5f5',
  '&:hover': {
    backgroundColor: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[50],
  }
}));

export const MuiCardAddOrg = styled(Card)(({ theme }) => ({
  width: 251,
  height: 305,
  borderRadius: 19,
  border: '2px dashed #E0E0E0', // Cambia el ancho del borde
  borderStyle: 'dashed',
  backgroundClip: 'padding-box', // Para que el borde no se superponga
  borderSpacing: '20px', // Control indirecto para aumentar la separación visual
  '&:hover': {
    border:`2px dashed ${GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[500]}`,
    '& .MuiIconButton-root': {
      backgroundColor: GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[50],
    }
  },

}));

export const MuiCardActionArea = styled(CardActionArea)(({ theme }) => ({
  position: 'relative',
  justifyContent: 'center',
  height: 'inherit',
  cursor: 'point',
}));

export const MuiCardMedia = styled(CardMedia)(({ theme }) => ({
  width: 219,
  height: 219,
  margin: 'auto',
  backgroundSize: 'contain',
  backgroundColor: '#FFFFFF',
  borderRadius: 11,
}));

export const MuiDescription= styled('div')(({ theme }) => ({
  marginTop: 14,
  marginBottom: 6,
  display: 'flex !important',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 48,
}));

export const MuiName= styled('div')(({ theme }) => ({
  width: '100%',
  whiteSpace: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}));

export const MuiTypographyName = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  color:'#212121',
  fontFamily: 'Montserrat',
  fontWeight:700,
  fontSize:'16px'
}));

export const MuiIconButton = styled(IconButton)(({ theme }) => ({
  '&:hover': {
    backgroundColor: GLOBAL.CUSTOM_ERROR_COLOR_PALETTE[50],
  },
}));

export const MuiMediaSkeleton = styled(CustomSkeleton)(({ theme }) => ({
  margin: 'auto',
  borderRadius: 11,
  height: 288,
  color: 'gray',
  // margin: theme.spacing(1),
  backgroundSize: 'contain',
}));

export const MuiCustomSkeleton = styled(CustomSkeleton)(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
}));

export const linkStyle = {
  color: blueGrey.A700,
  textDecoration: 'none',
};

export const iconStyle = {
  width: '17px !important',
  height: '17px !important',
  color:'#212121'
};
