import { useQuery } from 'react-query';

import { status200 } from '../status.utils';
import axios from '../axios/axiosInstance';
import apiPaths from '../apiPaths';
import {useAuth} from "../../components/providers/AuthProvider";

const fetchCartoPiaToken = () =>
  axios.get(apiPaths.refreshCartoPiaToken, status200).then((resp) => resp.data);

const useCartoPiaToken = () =>{
  const {isAuthenticated} = useAuth();
  return useQuery('carto-pia-token', () => fetchCartoPiaToken(), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    enabled: isAuthenticated,
  });
}


export default useCartoPiaToken;
