import {
  Box,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Switch
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { PinDrop, SystemUpdateAlt } from '@mui/icons-material';
import DrawIcon from '@mui/icons-material/Draw';
import { GLOBAL } from '../../../../utils/constants';
import { generateDynamicShades } from '../../../../utils/colors';

export const MuiBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 2,
  mb: '18px'
}));

export const MuiBoxBtnContainer = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  marginBottom: '12px',
  marginRight: '16px',
  display: 'flex',
  justifyContent: 'flex-end'
}));

export const MuiToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  gap: '1px',
  padding: '4px 7.86px 4px 7.86px',
  '& .MuiToggleButtonGroup-grouped':{
    border: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${lighten( theme.palette.primary.main , 0.9)}`,
    }
  },
  '& .Mui-selected':{
    borderRadius: '12px',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonIsochrone = styled(ToggleButton)(({ theme }) => ({
  width: '31.71px',
  height: '24px',
  borderRadius: '12px',
  color: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
  '& .Mui-selected':{
    backgroundColor: `${GLOBAL.COLOR_THEME.PRIMARY} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonDrawn = styled(ToggleButton)(({ theme }) => ({
  width: '31.71px',
  height: '24px',
  borderRadius: '12px',
  color: `${theme.palette.primary.main} !important`,
  '& .Mui-selected':{
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white !important",
  },
}));

export const MuiToggleButtonFromLayer = styled(ToggleButton)(({ theme }) => ({
  width: '31.71px',
  height: '24px',
  borderRadius: '12px',
  color: `${theme.palette.primary.main} !important`,
  '& .Mui-selected':{
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "white !important",
  },
}));

export const MuiPinDrop = styled(PinDrop)(({ theme }) => ({
  width: '16px !important' ,
  height: '16px !important',
  //padding: '2px',
}));

export const MuiDrawIcon = styled(DrawIcon)(({ theme }) => ({
  width: '16px !important' ,
  height: '16px !important',
  borderRadius: '12px',
  //padding: '2px',
  fontSize: '16px',
}));

export const MuiSystemUpdateAlt = styled(SystemUpdateAlt)(({ theme }) => ({
  width: '16px !important' ,
  height: '16px !important',
  borderRadius: '12px',
  //padding: '2px',
}));

export const stylePopover = {
  '& .MuiPopover-paper': {
    width: '298px',
    height: '233px',
    borderRadius: '12px'
  }
}
export const MuiBox = styled(Box)(({ theme }) => ({
  width: '104px',
  height: '32px',
  border: `1px solid ${generateDynamicShades( GLOBAL.COLOR_THEME.PRIMARY)[500]}`,
  backgroundColor: `${lighten( GLOBAL.COLOR_THEME.PRIMARY , 0.8)}`,
  borderRadius: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

export const MuiButton = styled(Button)(({ theme }) => ({
  color:`${theme.palette.primary.main} !important`,
  borderRadius: '20px !important',
  borderColor: theme.palette.primary.main,
  width:'120px',
  height:'37px',
  '&:hover':{
    backgroundColor: lighten(theme.palette.primary.main, 0.8)
  }
}));
