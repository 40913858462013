import React from 'react';
import { TextField, Typography, Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  labelInputStyle,
  adornmentColorStyle,
  getTextFieldWrapperStyle,
  getTextFieldStyle,
} from './styles/colorPickerField';

const ColorPickerField = ({ name, label, defaultValue = '#000000', margin='0px', ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Box sx={{ position: 'relative', width: '173px', margin }}>
          <div style={getTextFieldWrapperStyle(field)}>
            <input
              type="color"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              style={getTextFieldStyle()}
              {...props}
            />
          </div>
          <TextField
            fullWidth
            variant="outlined"
            label={label}
            value={field.value.replace('#', '')}
            onChange={(e) => field.onChange(`#${e.target.value}`)}
            InputLabelProps={{ style: labelInputStyle }}
            InputProps={{
              sx: {
                input: {
                  paddingLeft: '0px !important',
                },
              },
              style: { paddingLeft: '48px', paddingRight: '16px' },
              endAdornment: (
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '6px' }}>
                  <Typography
                    component="span"
                    fontFamily="Montserrat"
                    fontWeight={400}
                    style={adornmentColorStyle}>
                    HEX
                  </Typography>
                </Box>
              ),
            }}
          />
        </Box>
      )}
    />
  );
};

export default ColorPickerField;