export const textFieldCommonStyle = {
    width: '173px',
    height: '56px',
    '& .MuiInputLabelRoot': {
      fontFamily: 'Montserrat',
    },
  };
  
export const labelInputStyle = {
  color: '#424242',
  fontSize: 16,
  fontFamily: 'Montserrat',
};

export const adornmentColorStyle = {
  color: '#9E9E9E',
  marginTop: '0px',
};

export const getTextFieldStyle = () => ({
  opacity: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  position: 'absolute',
});

export const getTextFieldWrapperStyle = (field) => ({
  position: 'absolute',
  top: '50%',
  left: '16px',
  transform: 'translateY(-50%)',
  width: '24px',
  height: '24px',
  borderRadius: '4px',
  backgroundColor: field.value,
  border: '1px solid #ccc',
  cursor: 'pointer',
  zIndex: 2,
});