/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import {
  Container,
  Grid,
  IconButton,
  Toolbar,
  useMediaQuery
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'components/providers/AuthProvider';
import { usePermissions } from 'components/hooks/usePermissions';
import useOrganizations from 'api/hooks/useOrganizations';
import { useTranslation } from '../../providers/TranslationProvider';

import DeleteOrganization from './DeleteOrganization';
import {
  containerStyle,
  MuiButton, MuiButtonTypography, MuiCards,
  MuiGrid, MuiGridContainer, MuiSubTitle, MuiTitle, notPaddingStyle
} from './styles/organizations';
import CardOrg from './Card';
import CardSkeleton from './CardSkeleton';
import AddOrUpdateOrganization from './AddOrUpdateOrganization';
import { GLOBAL } from 'utils/constants';
import { useTheme } from 'components/providers/CustomThemeProvider';
import {
  MuiCardActionArea,
  MuiCardAddOrg,
} from './styles/card';

const ActionBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const { hasPermissionFor } = usePermissions();

  const handleMyWorkSpace = () => navigate('/orgs/workspace/maps');

  const handleNewOrganization = () =>
    navigate(`${location.pathname}?create=organization`, {
      replace: true,
    });

  return (
    <Grid container>
      <Grid item>
        {hasPermissionFor('create_org') && (
          <MuiButton
            variant='outlined'
            onClick={handleNewOrganization}
            startIcon={<AddIcon />}
          >
            <MuiButtonTypography>
              {t('new_org')}
            </MuiButtonTypography>
          </MuiButton>
        )}
      </Grid>
      <Grid item>
        <MuiButton
          variant='outlined'
          color='primary'
          onClick={handleMyWorkSpace}
          style={{marginLeft:10}}
          startIcon={<PersonIcon color='#00BBD4'/>}
        >
          <MuiButtonTypography>
            {t('my_workspace')}
          </MuiButtonTypography>
        </MuiButton>
      </Grid>
    </Grid>
  );
};

const CardAddOrg = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNewOrganization = () =>
    navigate(`${location.pathname}?create=organization`, {
      replace: true,
    });

  return (
    <MuiCardAddOrg elevation={0}>
      <MuiCardActionArea onClick={handleNewOrganization}>
        <IconButton
          component="span"
          sx={{backgroundColor:'#f5f5f5', marginLeft:'5rem'}}
        >
          <AddIcon style={{fontSize:'72px'}}/>
        </IconButton>
      </MuiCardActionArea>
    </MuiCardAddOrg>
  );
};

const Organizations = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useOrganizations();
  const { user } = useAuth();
  const [ allowedOrganizations, setAllowedOrganizations ] = React.useState( [] );

  const { setColors, theme } = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  React.useEffect( () => {
    if (!isLoading) {
      if ( user.type === 'admin' || user.type === 'super_admin' ) {
        setAllowedOrganizations(data);
      } else {
        setAllowedOrganizations( data.filter( org => user.non_admin_users_orgs_ids_access_permissions.includes( org._id ) || org._id === user.org_id ) );
      }
    }
  }, [ data, user, isLoading ]);

  React.useEffect( () => {
    setColors( GLOBAL.CUSTOM_PRIMARY_COLOR_PALETTE[500], GLOBAL.CUSTOM_SECONDARY_COLOR_PALETTE[400]);
  }, [ ]);

  const cardSkeletons = Array.from(Array(5).keys());

  return (
    <Container  maxWidth='xl' style={isLargeScreen ? containerStyle : notPaddingStyle}>
      <Toolbar sx={{ paddingRight: '0px !important', paddingLeft: '0px !important' }}>
        <MuiGrid container justifyContent='space-between'>
          <Grid item>
            <MuiTitle variant='h4' component='h2'>
              {t('orgs')}
            </MuiTitle>
            <MuiSubTitle variant='subtitle1'>
              { t('organizations_dashboard_subheader') }
            </MuiSubTitle>
          </Grid>
          <Grid item>
            <ActionBar />
          </Grid>
        </MuiGrid>
      </Toolbar>
      <DeleteOrganization />
      <AddOrUpdateOrganization />
      <MuiGridContainer container data-cy="organizationsList">
        {isLoading ? (
          <>
            {cardSkeletons.map((c) => (
              <CardSkeleton key={c} />
            ))}
          </>
        ) : (
          <>
            {allowedOrganizations.map((organization) => {
              if (user.type === 'admin')
                return (
                  user.admin_rights.includes(organization._id) && (
                    <MuiCards item key={organization._id}>
                      <CardOrg organization={organization} action='Update' />
                    </MuiCards>
                  )
                );
              return (
                <MuiCards item key={organization._id}>
                  <CardOrg organization={organization} action='Update' />
                </MuiCards>
              );
            })}
            <CardAddOrg/>
          </>
        )}
      </MuiGridContainer>
    </Container>
  );
};
export default Organizations;
